import React from "react";
import * as IMAGES from "../Images";

import "./Error.css";

class Error extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render = () => {
    return (
      <div className="error-container">
        <img
          src={IMAGES.FETCH_ERROR_IMAGE}
          alt="Fetch Error"
          style={{ width: "200px" }}
        />
        <div className="error-content">Link seems to be broken or filled.</div>
      </div>
    );
  };
}

export default Error;
