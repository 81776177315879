import React from "react";
import * as IMAGES from "../Images";

import "./Loader.css";

class Loader extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render = () => {
    return (
      <div className="loader-container">
        <img
          src={IMAGES.APP_LOGO}
          alt="Gear Loader"
          style={{ width: "250px" }}
        />
      </div>
    );
  };
}

export default Loader;
