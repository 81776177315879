import React, { Suspense, lazy } from "react";
import "./App.css";

import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { Router, Switch, Route } from "react-router-dom";
import { createBrowserHistory } from "history";

import Loader from "./Util/Loader/Loader";
import Error from "./Util/Error/Error";

const Form = lazy(() => import(/* webpackChunkName: "Form" */ "./Form/Form"));
const SuccessPage = lazy(() =>
  import(/* webpackChunkName: "SuccessPage" */ "./Form/SuccessPage")
);
const GeneralFeedback = lazy(() =>
  import(/* webpackChunkName: "Form" */ "./GeneralFeedback/GeneralFeedback")
);

const SuccessFeedPage = lazy(() =>
  import(
    /* webpackChunkName: "SuccessPage" */ "./GeneralFeedback/SuccessPage.js"
  )
);

const CampaignFeedback = lazy(() =>
  import(/* webpackChunkName: "Form"*/ "./CampaignFeedback/CampaignFeedback")
);

const SuccessCampPage = lazy(() =>
  import(/* webpackChunkName: "SuccessCamp"*/ "./CampaignFeedback/SuccessPage")
);

const theme = createTheme({
  palette: {
    primary: {
      main: "#e31e24",
    },
    secondary: {
      main: "#161616",
    },
  },
});

//
// GTag measurement ID

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.customHistory = createBrowserHistory();
  }

  render = () => {
    return (
      <ThemeProvider theme={theme}>
        <Router history={this.customHistory}>
          <Suspense
            fallback={
              <div className="loader-container">
                <Loader />
              </div>
            }
          >
            {/* <Header history={this.customHistory} /> */}
            <Switch>
              <Route exact path="/" component={Error} />
              <Route path="/form/:name/:uid" component={Form} />
              <Route path="/success" component={SuccessPage} />
              <Route path="/success-feed" component={SuccessFeedPage} />
              <Route path="/feedback/:branch" component={GeneralFeedback} />
              <Route path="/campaign/:cid" component={CampaignFeedback} />
              <Route path="/success-camp" component={SuccessCampPage} />
              <Route component={Error} />
            </Switch>
          </Suspense>
        </Router>
      </ThemeProvider>
    );
  };
}

export default App;
